import { currentLocale, type Locale } from '../../i18n';

/**
 * Delays the execution for a specified amount of time.
 * @param ms - The number of milliseconds to delay the execution. Default is 500ms.
 * @returns A promise that resolves after the specified delay.
 */
export const sleep = (ms: number = 500) => new Promise(r => setTimeout(r, ms));

/**
 * Sets the loading state of a form.
 * @param form - The HTML form element.
 * @param isLoading - A boolean indicating whether the form is in a loading state.
 */
export function setLoadingState(form: HTMLElement, isLoading: boolean) {
  const submitButtons = form.querySelectorAll('button[type="submit"]');
  submitButtons.forEach(sb => sb?.setAttribute('data-loading', String(isLoading)));
}

/**
 * Retrieves an array of localized month labels based on the specified format.
 * @param format The format of the month labels. Defaults to 'long'.
 * @returns An array of objects containing the month index and label.
 */
export function getI18nMonths(format: 'long' | 'short' = 'long') {
  const locale = currentLocale();
  const formatter = new Intl.DateTimeFormat(locale, { month: format, timeZone: 'UTC' });
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
    const mm = month < 10 ? `0${month}` : month;
    return new Date(`1991-${mm}-01T00:00:00+00:00`);
  });

  return months.map((date, i) => ({ monthIndex: i, label: formatter.format(date) }));
}

/**
 * Checks whether the specified locale is valid.
 * @returns A boolean indicating whether the specified locale is valid.
 */
export function isValidDate(date: string | number | Date) {
  return !isNaN(new Date(date) as any);
}

/**
 * Checks whether the specified date is valid.
 * @param day - The day of the month.
 * @param month - The month of the year.
 * @param year - The year.
 * @returns A boolean indicating whether the specified date is valid.
 */
export function isValidSelectDate(day: string, month: string, year: string) {
  const dateStr = `${month}/${day}/${year}`;
  const date = new Date(dateStr);

  return isValidDate(date) && date.getMonth() + 1 === Number(month);
}

function stringifyDayMonth(value: string) {
  const numberValue = Number(value);
  if (!value) {
    return '';
  }

  if (numberValue < 10) {
    return `0${numberValue}`;
  }

  return value;
}

export function simpleIsoDateFromSelect(day: string, month: string, year: string) {
  const dateString = `${year}-${stringifyDayMonth(month)}-${stringifyDayMonth(day)}`;

  return dateString;
}

/**
 * Calculates the difference in years between a given date and the current date.
 * @param day - The day of the month as a string.
 * @param month - The month as a string.
 * @param year - The year as a string.
 * @returns The difference in years between the given date and the current date.
 */
export function getYearDifference(day: string, month: string, year: string): number {
  const dateStr = `${month}/${day}/${year}`;
  const date = new Date(dateStr);
  const currentDate = new Date();

  const differenceInYears = currentDate.getFullYear() - date.getFullYear();

  return differenceInYears;
}

/**
 * Checks if the provided date of birth matches the minimum required age.
 * @param day - The day of birth.
 * @param month - The month of birth.
 * @param year - The year of birth.
 * @param requiredAge - The minimum required age.
 * @returns True if the age is greater than or equal to the required age, false otherwise.
 */
export function matchesMinAge(day: string, month: string, year: string, requiredAge: number): boolean {
  const age = getAgeFromDate(day, month, year);

  return age >= requiredAge;
}

/**
 * Calculates the age based on the given date of birth.
 *
 * @param {string} day - The day of the birthdate.
 * @param {string} month - The month of the birthdate.
 * @param {string} year - The year of the birthdate.
 * @returns {number} The calculated age.
 */
export function getAgeFromDate(day: string, month: string, year: string, forceToday?: Date): number {
  const birthDate = new Date(`${year}-${stringifyDayMonth(month)}-${stringifyDayMonth(day)}`);
  const today = forceToday ?? new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

/**
 * Parses the default value of a form field.
 *
 * @param value - The default value to parse.
 * @returns The parsed default value, or null if the value includes '{{'.
 */
export function parseDefaultValue(value: string | null | undefined) {
  if (value?.startsWith('{{')) {
    return null;
  }

  return value;
}

/**
 * Activates the specified step in a form by adding the 'active' class to the corresponding element.
 * @param form - The HTML form element.
 * @param activateStep - The step number to activate. Defaults to 2.
 */
export function goToFormStep(form: HTMLFormElement, activateStep: number = 2, focusQuerySelector?: string) {
  Array.from(form.querySelectorAll('[data-step]')).forEach(div => {
    if (div.getAttribute('data-step') === String(activateStep)) {
      div.classList.add('active');
    } else {
      div.classList.remove('active');
    }
  });

  if (focusQuerySelector) {
    const focusElement = form.querySelector<HTMLInputElement>(focusQuerySelector);
    if (focusElement) {
      focusElement?.focus?.();
    }
  }
}

/**
 * Handles the response from a fetch request.
 * If the response is not ok, it throws an error with the details.
 * @param response - The response object from the fetch request.
 * @returns The response object if it is ok.
 * @throws An error with the details if the response is not ok.
 */
export async function handleFetchResponse(response: Response) {
  if (!response.ok) {
    if (response.headers?.get('content-type')?.includes('application/json')) {
      const errorOut = await response.json();

      throw errorOut;
    }

    const errorDetails = await response.text();
    throw new Error(`Fetch error: ${response.status} ${response.statusText}. Details: ${errorDetails}`);
  }

  return response;
}

export function simulateFormAction(formData: FormData | null, formRef: HTMLFormElement) {
  const privateForm = document.createElement('form');
  privateForm.action = formRef.action;
  privateForm.method = formRef.method;
  privateForm.style.display = 'none';

  if (formData) {
    const data = Object.fromEntries(formData);
    Object.entries(data).forEach(([key, value]) => {
      const input = document.createElement('input');
      input.name = key;
      input.value = value.toString();
      privateForm.appendChild(input);
    });

    formRef.append(privateForm);
  }

  privateForm.submit();
}

export function inputLanguageFromLocale(locale: Locale) {
  switch (locale) {
    case 'cs':
      return 'czech';
    case 'en':
      return 'english';
    default:
      return 'czech';
  }
}
